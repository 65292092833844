<template>
    <path class="primary" opacity="0.05" d="m63.5 150c0 31.5 25.5 57 57 57s57-25.5 57-57-25.5-57-57-57-57 25.6-57 57z"/>
    <path d="m312.4 210.1c-0.7 0-1.2-0.5-1.2-1.2v-23.1c0-0.7 0.5-1.2 1.2-1.2s1.2 0.5 1.2 1.2v23.2c0 0.6-0.6 1.1-1.2 1.1z"/>
    <path class="primary" d="m47.4 49 0.3-0.3c8.1-0.2 12.8-3.9 12.8-9.9 0-5-3.9-9.3-10.2-9.3-4.9 0-8.7 2.4-11.8 6.3l-2.3-1.8c3.5-4.4 7.7-7.3 14.2-7.3 8.1 0 13.3 5.4 13.3 12.1 0 7.6-5.9 11.7-13 12.5l-0.6 7.7h-2l-0.7-10zm-0.3 16h4v5.2h-4v-5.2z"/>
    <path class="primary" d="m224.8 47.8 0.2-0.2c4.4-0.1 6.8-1.9 6.8-4.9 0-2.5-1.9-4.4-5-4.4-2.5 0.1-4.7 1.2-6.2 3.2l-1.7-1.7c1.9-2.5 4.9-4 8-4 4.6 0 7.6 2.9 7.6 6.8 0 4.3-3.2 6.4-7.1 6.9l-0.3 3.7h-1.7l-0.6-5.4zm-0.2 8.7h3.1v3.6h-3.1v-3.6z"/>
    <path class="primary" d="m147.9 14.9 0.2-0.2c5.6-0.1 8.5-2.4 8.5-6.1 0-3.1-2.4-5.6-6.3-5.6-3.2 0-5.6 1.5-7.8 4l-2.1-2c2.4-3.2 6.1-5 10.1-5 5.8 0 9.6 3.6 9.6 8.6 0 5.4-4 8-8.9 8.7l-0.4 4.6h-2.2l-0.7-7zm-0.2 11h3.9v4.5h-3.9v-4.5z"/>
    <path d="m210.8 161.4c-3.7 0-6.7-3-6.7-6.7s3-6.7 6.7-6.7 6.7 3 6.7 6.7c-0.1 3.7-3.1 6.7-6.7 6.7zm0-11c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3-2-4.3-4.3-4.3z"/>
    <path d="m280.4 153.4c-0.7 0-1.2 0.5-1.2 1.2v36.9c0 3.4-1.8 6.9-5.7 6.9h-125.1c-3.7 0-8.1-3.1-8.1-6.9v-59.9c0-0.7-0.5-1.2-1.2-1.2s-1.2 0.5-1.2 1.2v59.9c0 5.2 5.6 9.3 10.5 9.3h44.5v15.4h-5.6-0.2c-5.6 0.1-10 4.7-9.9 10.3 0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-0.3c0-4.2 3.5-7.6 7.8-7.6h7.4c0.7 0 1.2-0.5 1.2-1.2 0-0.4-0.2-0.8-0.6-1v-15.6h30.6v15.4h-11.8c-0.7 0-1.2 0.5-1.2 1.2s0.5 1.2 1.2 1.2h16.4c4.5-0.1 8.2 3.5 8.3 8 0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2c-0.1-5.8-4.9-10.4-10.7-10.3h-2.2v-15.4h45.2c4.8 0 8.1-3.8 8.1-9.3v-36.9c-0.1-0.8-0.6-1.3-1.3-1.3z"/>
    <path d="m166.3 215.1-3.8-3.8c-0.3-0.3-0.7-0.4-1.1-0.3l-8.4 2.3-12.2-4.4-14.4-5.2c-1.3-6.1-11.1-50.5-15.8-60.1-0.1-0.2-0.3-0.4-0.5-0.5-6.3-3.5-17-7.5-19.8-8.5-0.3-2.6-0.4-5.3-0.2-8 4.5-2.5 7.3-5 7.3-15.7 0-0.7-0.5-1.2-1.2-1.2s-1.2 0.5-1.2 1.2c0 10.2-2.6 11.7-6.5 13.8-0.6 0.2-4.8 1.2-8.4-0.8-0.6-0.3-1.3-0.1-1.6 0.4-0.3 0.6-0.1 1.3 0.4 1.6 1.9 1.1 4 1.6 6.2 1.6 0.9 0 1.7-0.1 2.5-0.2-0.1 2.4 0 4.8 0.2 7.2-1.9 0.9-3.6 2.2-5 3.7-0.2 0.2-0.3 0.5-0.3 0.8 0 0.7 0.5 1.2 1.2 1.2 0.3 0 0.7-0.1 0.9-0.4 1.2-1.4 2.8-2.4 4.4-3.2 1.6 0.6 13.1 4.8 19.6 8.3 4.9 10.6 15.5 59.4 15.6 59.9 0.1 0.4 0.4 0.7 0.8 0.9l14 5.1-1.9 5.7c-0.2 0.6 0.1 1.3 0.8 1.5 0.1 0 0.2 0.1 0.4 0.1 0.5 0 1-0.3 1.1-0.8l1.9-5.6 7.5 2.7-2.4 7c0 0.1-0.1 0.2-0.1 0.4 0 0.7 0.5 1.2 1.2 1.2 0.5 0 1-0.3 1.1-0.8l2.4-7 1.6 0.6c0.2 0.1 0.5 0.1 0.7 0l8.1-2.2 3.3 3.3c0.2 0.2 0.5 0.3 0.8 0.3s0.6-0.1 0.8-0.3c0.4-0.6 0.4-1.3 0-1.8z"/>
    <path d="m84.2 93.2c4.3 0.3 8.1 0.6 10.5-1.6 0.3 1.9 0.5 4.7 0.4 8.6v0.1c0 0.6 0.6 1.1 1.2 1h0.1c0.6 0 1.1-0.5 1.1-1.1 0-10.3-1.4-13.2-1.6-13.5-1.8-5.3-6.6-9.5-12.7-11-8.1-2-16.5 1.4-21.1 8.4-3.9 6.4-3.1 15.5-3.1 15.8 0.1 0.6 0.6 1.1 1.2 1.1h0.1c0.7-0.1 1.1-0.6 1.1-1.3 0-0.1-0.7-8.7 2.8-14.4 4-6.1 11.4-9 18.5-7.3 5.6 1.4 10.1 5.6 11.3 10.5-1.3 2.8-4.1 2.7-9.7 2.3-1.8-0.1-3.8-0.3-5.9-0.3-2-0.1-3.9 0.5-5.4 1.9-0.9 1-1.5 2.2-1.5 3.6 0 0.6 0.5 1.1 1.2 1.1 0.6 0 1.1-0.6 1.1-1.2 0-0.7 0.3-1.4 0.8-1.9 1-0.9 2.3-1.3 3.7-1.2 2.2 0.1 4.1 0.3 5.9 0.4z"/>
    <path d="m326.6 227.3h-292.9c0.2-6 1.5-50.4 2.8-67.2 0.4-5.6 1.7-9.7 14.4-16.7 0.5-0.3 1.5-0.8 2.7-1.5 2.3-1.3 5.6-3.2 7.3-4 0.1 0 0.2-0.1 0.3-0.1l4.9-2.8 0.4-22.4c0-0.6-0.5-1.2-1.1-1.2-0.7 0-1.2 0.5-1.2 1.1l-0.4 21.1-3.3 1.9h-0.1c-1.4 0.7-5.2 2.8-8 4.4-1.2 0.7-2.2 1.2-2.6 1.5-12.6 6.8-15.1 11.4-15.7 18.5-1.3 17-2.6 61.7-2.8 67.5h-30.1c-0.7 0-1.2 0.5-1.2 1.2s0.5 1.2 1.2 1.2h325.4c0.7 0 1.2-0.5 1.2-1.2s-0.6-1.3-1.2-1.3z"/>
    <path d="m115.7 218.5-6.1-6.6c-0.3-0.3-0.8-0.5-1.2-0.3l-12.1 3.5-44.4-4.9 3.2-42.8c0-0.6-0.4-1.2-1.1-1.2-0.7-0.1-1.2 0.4-1.3 1.1l-3.3 43.9c0 0.6 0.4 1.2 1.1 1.3l23.5 2.5c0 0.1-0.1 0.3-0.1 0.4v6.2c0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-6.2-0.2l11.2 1.2-0.1 6.8v0.1c0.1 0.6 0.6 1 1.2 1h0.1c0.6 0 1.1-0.4 1.1-1l0.1-6.6 6.4 0.7h0.5l11.7-3.3 5.6 6c0.2 0.2 0.5 0.4 0.9 0.4 0.3 0 0.6-0.1 0.8-0.3 0.3-0.5 0.3-1.2-0.1-1.7z"/>
    <path d="m83.9 220.2h-0.8-0.1c-0.7 0-1.2 0.6-1.1 1.2s0.6 1.2 1.2 1.1h0.8 0.1c0.7 0 1.2-0.6 1.1-1.2 0-0.6-0.5-1.1-1.2-1.1z"/>
    <path d="m103.6 171.7h-7.4c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h7.4c0.7 0 1.2 0.5 1.2 1.2s-0.5 1.2-1.2 1.2z"/>
    <path class="primary" d="m101.2 98.9h-26.7c-0.7 0-1.2 0.5-1.2 1.2v7.1c0 0.7 0.5 1.2 1.2 1.2h10.6c0.7 0 1.2-0.5 1.2-1.2v-5.9h3v5.9c0 0.7 0.5 1.2 1.2 1.2h10.6c0.7 0 1.2-0.5 1.2-1.2v-7.1c0-0.7-0.5-1.2-1.1-1.2zm-17.2 7.1h-8.3v-4.7h8.3v4.7zm16 0h-8.3v-4.7h8.3v4.7z"/>
    <path class="primary" d="m80.8 115.1-9.7-4.5c0.6-1 0.9-2.2 0.9-3.5v-0.6c-0.3-2.9-2.3-5.2-5-5.9-0.2-3.7-0.2-18.8 12.7-23.1 0.6-0.2 1-0.9 0.7-1.5-0.2-0.6-0.9-1-1.5-0.7-14.1 4.7-14.5 20.5-14.3 25.2-3.7 0.4-6.4 3.6-6 7.3 0.3 3.7 3.6 6.4 7.3 6.1 1.4-0.1 2.6-0.5 3.6-1.3l10.3 4.8c0.2 0.1 0.3 0.1 0.5 0.1 0.5 0 0.9-0.3 1.1-0.7 0.2-0.7 0-1.4-0.6-1.7zm-16.1-3.8c-2.4-0.3-4.1-2.5-3.7-4.9 0.3-2.4 2.5-4.1 4.9-3.7 2.2 0.2 3.9 2.1 3.8 4.3v0.6c-0.4 2.4-2.6 4.1-5 3.7z"/>
    <path class="primary" d="m90.2 191.9c-0.6 0-1.1-0.4-1.2-1l-6-42.6 0.2-0.3c0.1-0.1 1.4-2.8 2.3-4.4 0.3-0.6 1.1-0.7 1.6-0.4s0.7 1 0.4 1.6c-0.7 1.2-1.7 3.2-2.1 3.9l6 41.9c0.1 0.6-0.4 1.2-1 1.3h-0.2z"/>
    <path class="primary" d="m77.2 193.6c-0.7-0.1-1.2-0.6-1.2-1.3 0-0.3 0.1-31-0.2-42.4l-2.7-5.2c-0.3-0.6-0.1-1.3 0.5-1.6s1.3-0.1 1.6 0.5l3 5.7v0.3c0.3 11.2 0.2 42.5 0.2 42.8 0 0.6-0.6 1.1-1.2 1.2z"/>
    <path class="primary" d="m210 93.6c-0.3-0.6-3.1-5.8-5.5-7.6-2.8-2.2-7.3-1.2-9.1-0.6l-31.9-31.3c-0.2-1.2-0.5-2.3-0.8-3.5l-0.1-0.1c-1.8-4.8-4.2-6.3-5.8-6.7-2.9-0.7-8.7 0.2-9.4 0.3-0.5 0.1-0.9 0.5-1 1l-0.3 1.7c-0.1 0.5 0.2 1 0.6 1.2 0.2 0.1 4.4 2.3 5.8 3 0.6 0.2 1 0.7 1.2 1.3s0.2 1.2-0.1 1.7c0 0-1.2 2.2-3.1 1.3-1.5-0.7-5.7-2.7-5.9-2.8-0.5-0.2-1-0.1-1.4 0.3l-1.2 1.3c-0.3 0.4-0.4 0.9-0.2 1.4 0.3 0.6 3.1 5.8 5.4 7.6 1.3 0.9 2.9 1.4 4.6 1.3 1.5 0 3-0.3 4.5-0.7l31.9 31.3c0.2 1.2 0.5 2.3 0.8 3.5l0.1 0.1c1.8 4.8 4.2 6.3 5.8 6.7 1 0.2 1.9 0.3 2.9 0.3 2.2 0 4.3-0.2 6.4-0.6 0.5-0.1 0.9-0.5 1-1l0.3-1.7c0.1-0.5-0.2-1-0.6-1.2-0.2-0.1-4.4-2.3-5.8-3-0.6-0.2-1-0.7-1.2-1.3s-0.2-1.2 0.1-1.7c0 0 1.2-2.2 3.1-1.2 1.4 0.7 5.7 2.7 5.9 2.8 0.5 0.2 1 0.1 1.4-0.3l1.2-1.3c0.5-0.5 0.6-1 0.4-1.5zm-2.5 0.4c-1.3-0.6-4-1.9-5.1-2.4-2.9-1.4-5.4 0.6-6.2 2.3-0.5 1.1-0.6 2.4-0.2 3.5 0.4 1.2 1.3 2.2 2.4 2.7s3.7 1.9 5 2.6c-2.1 0.3-5.8 0.7-7.6 0.2-0.9-0.2-2.6-1.2-4.2-5.3l-0.1-0.1c-0.1-0.3-0.5-2.1-0.8-3.5v-0.1c0-0.1 0-0.1-0.1-0.2 0-0.1-0.1-0.2-0.2-0.3v-0.1l-32.7-32.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.1s-0.2 0-0.2-0.1h-0.2-0.3-0.2c-1.4 0.5-5.5 1.5-7.5 0-1.4-1.1-3.4-4.3-4.4-6.1 1.3 0.6 4 1.9 5.1 2.4 2.9 1.4 5.4-0.6 6.2-2.3 0.5-1.1 0.6-2.4 0.2-3.5-0.4-1.2-1.3-2.2-2.4-2.7s-3.7-1.9-5-2.6c2.1-0.3 5.8-0.7 7.6-0.2 0.9 0.2 2.6 1.2 4.2 5.3l0.1 0.2c0.1 0.3 0.4 1.5 0.6 2.6-0.1 0.4 0 0.9 0.3 1.2l32.7 32.1 0.1 0.1c0.3 0.4 0.8 0.6 1.3 0.4 1.4-0.5 5.6-1.5 7.5 0 1.4 1.2 3.4 4.4 4.4 6.2z"/>
    <path class="primary" d="m183.7 68.8c-0.7 0-1.2-0.5-1.2-1.2 0-0.3 0.1-0.7 0.4-0.9l11-10.1c0.3-0.3 0.7-0.4 1.1-0.3l1.3 0.3 6.7-8.7-1.8-1.8-8.7 6.7 0.3 1.3c0.1 0.4 0 0.9-0.3 1.2l-11 10.1c-0.5 0.4-1.2 0.4-1.7-0.1s-0.4-1.2 0.1-1.7l10.5-9.6-0.3-1.3c-0.1-0.5 0.1-0.9 0.4-1.2l10.1-7.8c0.5-0.4 1.1-0.3 1.6 0.1l3.3 3.3c0.4 0.4 0.5 1.1 0.1 1.6l-7.8 10.1c-0.3 0.4-0.8 0.5-1.2 0.4l-1.4-0.3-10.7 9.6c-0.2 0.2-0.5 0.3-0.8 0.3z"/>
    <path class="primary" d="m171.9 82.6c-0.5-0.5-1.2-0.5-1.7 0s-0.5 1.2 0 1.7 0.5 1.4 0 1.9l-14.6 14.6-7.6-7.6 14.6-14.6c0.5-0.5 1.4-0.5 1.9 0s1.2 0.5 1.7 0 0.5-1.2 0-1.7c-1.4-1.4-3.8-1.4-5.2 0l-17.2 17.2c-1.4 1.4-1.4 3.8 0 5.2l5.7 5.7c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1l17.2-17.2c1.5-1.4 1.5-3.7 0-5.2zm-20.7 20.8-5.7-5.7c-0.5-0.5-0.5-1.4 0-1.9l0.9-0.9 7.6 7.6-0.9 0.9c-0.6 0.5-1.4 0.5-1.9 0z"/>
    <path class="primary" d="m159.2 124.5c-3.9 0-7.7-0.6-11.4-1.9-4.6-1.5-8.9-4-12.5-7.2l-17.1 4.5c-0.6 0.2-1.3-0.2-1.4-0.8-0.1-0.3 0-0.7 0.2-1l10-14.5c-6-14-1.9-30.2 10-39.7 0.5-0.4 1.3-0.3 1.7 0.2s0.3 1.3-0.2 1.7c-11.2 9-14.9 24.4-9 37.5 0.2 0.4 0.1 0.8-0.1 1.2l-8.6 12.4 14.6-3.9c0.4-0.1 0.8 0 1.1 0.3 3.5 3.3 7.6 5.7 12.1 7.2 13.6 4.5 28.6 0 37.5-11.3 0.4-0.5 1.2-0.6 1.7-0.2s0.6 1.1 0.2 1.6c-7.1 8.7-17.6 13.9-28.8 13.9z"/>
    <path class="primary" opacity="0.43" d="m147.4 45.2 9.7 0.5c1.6 1.6 2.8 3.6 3.5 5.8 1 3.5 7.2 7.9 7.2 7.9l26.3 28s7.8-2.3 9.1-1 6.5 7.5 5.7 7.7-9-1.5-9-1.5l-3.2 3 7.7 7.2-2.5 1.5-8-0.2-4.4-8.5-32.3-32.1-5-0.2-5.5-3-3.5-4.5 1.2-2 7.7 3 3.7-5.2-1.9-1.7-6.7-2.7 0.2-2z"/>
    <path class="primary" opacity="0.3" d="m159.9 78.2s3-2.6 4.5-0.7c1.8 1.9 3.8 3.6 5.9 5.2 0 0 2.7 4.2 0.1 5.3s-15.5 15.3-15.5 15.3-4.5 3.6-5.4 1.8-5.7-5.7-5.7-5.7-1.6-3.7 1.8-5.4 14.3-15.8 14.3-15.8z"/>
    <path class="primary" opacity="0.1" d="m191.7 91.5c0 18.3-14.3 32.2-32.7 32.2-10.4 0-16.9-1.9-22.9-9.4-1.2-0.2-16.3 5.1-17 4s9.4-12.9 8.9-14.1c-2.1-4.4-2.6-7.5-2.6-12.7 0-18.3 14.9-33.2 33.2-33.1 18.2 0 33.1 14.8 33.1 33.1z"/>
    <path d="m260.8 123.7c-3 0-5.4-2.3-5.5-5.3-0.1-1.5 0.5-3 1.5-4.1 0.1-0.1 0.2-0.3 0.4-0.4 0.1-0.1 0.2-0.3 0.4-0.4l12.9-8c0.6-0.3 1.3-0.2 1.6 0.4 0.2 0.3 0.2 0.8 0.1 1.1l-5.8 12.6c-0.2 0.4-0.3 0.8-0.6 1.2l-0.1 0.1c-0.2 0.3-0.4 0.5-0.6 0.8-1.1 1.3-2.6 2-4.3 2zm-2-8-0.2 0.2c-0.6 0.7-0.9 1.5-0.9 2.4 0.1 1.7 1.5 3 3.1 3 1 0 1.9-0.4 2.5-1.2 0.1-0.1 0.2-0.2 0.2-0.3s0.1-0.2 0.2-0.3c0.1-0.2 0.3-0.5 0.3-0.7v-0.1l4.2-9.1-9.2 5.7c0 0.2-0.1 0.3-0.2 0.4z"/>
    <path d="m250.3 117.3h-32c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h32c0.7 0 1.2 0.5 1.2 1.2 0 0.6-0.6 1.2-1.2 1.2z"/>
    <path d="m250.3 129.1h-20.1c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h20.1c0.7 0 1.2 0.5 1.2 1.2s-0.6 1.2-1.2 1.2z"/>
    <path d="m250.3 106h-20.7c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h20.7c0.7 0 1.2 0.5 1.2 1.2s-0.6 1.2-1.2 1.2z"/>
    <path d="m250.3 96.5h-11.3c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h11.2c0.7 0 1.2 0.5 1.2 1.2s-0.5 1.2-1.1 1.2z"/>
    <path d="m250.3 138h-8.3c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h8.3c0.7 0 1.2 0.5 1.2 1.2 0 0.6-0.6 1.2-1.2 1.2z"/>
    <path d="m292.7 87.7-4.6-4.6c-0.5-0.4-1.2-0.4-1.7 0l-3.9 3.9c-0.5 0.5-0.5 1.2 0 1.7l1.4 1.4-1.8 1.8c-5.7-5.3-13.4-8.5-21.8-8.5-0.7 0-1.2 0.5-1.2 1.2s0.5 1.2 1.2 1.2v4.7c0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-4.6c4.8 0.4 9.2 1.9 13.1 4.3l-2.7 3.5c-0.2 0.2-0.2 0.5-0.2 0.7 0 0.7 0.5 1.2 1.2 1.2 0.4 0 0.7-0.2 0.9-0.5l2.8-3.6c3.2 2.4 6 5.4 8 9-0.1 0-0.2 0-0.3 0.1l-4.6 2.3c-0.4 0.2-0.7 0.6-0.7 1.1 0 0.7 0.5 1.2 1.2 1.2 0.2 0 0.4 0 0.5-0.1l4.6-2.3c0.1-0.1 0.2-0.1 0.3-0.2 1.9 3.9 2.9 8.2 2.9 12.8 0 0.6 0 1.3-0.1 1.9h-4.5c-0.7 0-1.2 0.5-1.2 1.2s0.5 1.2 1.2 1.2h4.3c-0.5 3.3-1.5 6.4-3 9.3l-3.3-2.5c-0.5-0.4-1.3-0.3-1.7 0.2s-0.3 1.3 0.2 1.7l3.5 2.7c-2.3 3.7-5.4 6.8-9 9.1l-2.2-4.4c-0.3-0.6-1-0.8-1.6-0.5s-0.8 1-0.5 1.6l2.3 4.5c-3.6 2-7.8 3.2-12.1 3.4v-5c0-0.7-0.5-1.2-1.2-1.2s-1.2 0.5-1.2 1.2v5.2c-0.4 0.2-0.6 0.6-0.6 1 0 0.6 0.5 1.1 1.1 1.2 0.2 0.1 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3c11.1-0.4 20.7-6.5 26.1-15.4 0.1-0.1 0.2-0.1 0.3-0.2 0.1-0.2 0.2-0.4 0.2-0.6 2.6-4.7 4.1-10 4.1-15.7 0-8.3-3.3-15.8-8.5-21.5l1.8-1.8 1.5 1.5c0.2 0.2 0.5 0.3 0.8 0.3s0.6-0.1 0.8-0.3l3.9-3.9c0.6-0.7 0.6-1.4 0.2-1.9zm-4.8 3.1-2.9-2.9 2.2-2.2 2.9 2.9-2.2 2.2z"/>
    <path class="primary" d="m307 169.4-4.5-4.5c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.3-0.1-0.5-0.1s-0.3 0-0.5 0.1c-0.1 0-0.2 0.1-0.3 0.2 0 0-0.1 0-0.1 0.1l-4.4 4.4c-0.5 0.5-0.5 1.2 0 1.7s1.2 0.5 1.7 0l2.4-2.4v18.4c0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-18.4l2.4 2.4c0.5 0.5 1.2 0.5 1.7 0 0.4-0.5 0.4-1.2-0.1-1.7z"/>
    <path d="m306 55.8c0 1.1-0.9 2.1-2.1 2.1s-2.1-0.9-2.1-2.1c0-1.1 0.9-2.1 2.1-2.1s2.1 0.9 2.1 2.1z"/>
    <path d="m331.8 162.2c0 1.1-0.9 2.1-2.1 2.1s-2.1-0.9-2.1-2.1 0.9-2.1 2.1-2.1c1.1 0.1 2.1 1 2.1 2.1z"/>
    <path d="m15.6 195.9c0 1.1-0.9 2.1-2.1 2.1s-2.1-0.9-2.1-2.1c0-1.1 0.9-2.1 2.1-2.1s2.1 0.9 2.1 2.1z"/>
    <path d="m90 24-7.1-6.7 6.7-7.1 7.1 6.7-6.7 7.1zm-4.6-6.7 4.5 4.3 4.3-4.5-4.5-4.3-4.3 4.5z"/>
    <path class="primary" d="m315.9 145.9-7.5-7.1 7.1-7.5 7.5 7.1-7.1 7.5zm-5-7.2 4.9 4.6 4.6-4.9-4.9-4.6-4.6 4.9z"/>
    <path d="m278.6 42.8-3-3 3-3c0.3-0.3 0.3-0.9 0-1.3s-0.9-0.3-1.3 0l-3 3-3-3c-0.3-0.3-0.9-0.3-1.2 0-0.4 0.3-0.4 0.9 0 1.3l3 3-3 3c-0.2 0.2-0.3 0.4-0.3 0.6 0 0.5 0.4 0.9 0.9 0.9 0.2 0 0.5-0.1 0.6-0.3l3-3 3 3c0.2 0.2 0.4 0.3 0.6 0.3s0.5-0.1 0.6-0.3c0.4-0.3 0.4-0.8 0.1-1.2z"/>
    <path class="primary" d="m13.6 156.6-3.2-3.2 3.2-3.2c0.3-0.3 0.3-0.9 0-1.3-0.3-0.3-0.9-0.3-1.3 0l-3.2 3.2-3.1-3.1c-0.4-0.3-0.9-0.3-1.3 0-0.3 0.4-0.3 0.9 0 1.3l3.2 3.2-3.2 3.2c-0.2 0.2-0.3 0.4-0.3 0.6 0 0.5 0.4 0.9 0.9 0.9 0.2 0 0.5-0.1 0.6-0.3l3.2-3.2 3.2 3.2c0.2 0.2 0.4 0.3 0.6 0.3s0.5-0.1 0.6-0.3c0.5-0.4 0.5-0.9 0.1-1.3z"/>
    <path d="m261.5 81.8c-0.7 0-1.2-0.5-1.2-1.2v-3.6c0-0.7 0.5-1.2 1.2-1.2h4.7v-4.1h-11.2c-0.7 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2h12.4c0.7 0 1.2 0.5 1.2 1.2v6.5c0 0.7-0.5 1.2-1.2 1.2h-4.7v2.4c0 0.6-0.5 1.2-1.2 1.2z"/>
    <path d="m42 114.4-4.5-4.5c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.3-0.1-0.5-0.1s-0.3 0-0.5 0.1c-0.1 0-0.2 0.1-0.3 0.2 0 0-0.1 0-0.1 0.1l-4.4 4.4c-0.5 0.5-0.5 1.2 0 1.7s1.2 0.5 1.7 0l2.4-2.4v17.4c0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-17.4l2.4 2.4c0.5 0.5 1.2 0.5 1.7 0 0.3-0.5 0.3-1.2-0.1-1.7z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 331.8, height: 229.7 });
    },
    emits: ['dimensions'],
};
</script>
